<template>
  <section class="container">
    <Loading v-if="loading.androidMonitoring" />
    <Empty v-else-if="androidMonitoring.detail.length === 0" :message="'Saat ini belum ada monitoring untuk ditampilkan.'" />
    <div v-else class="row">
      <div class="col-12 col-md-12 p-0">
        <div class="table-responsive">
          <table class="table table-monitoring table-striped w-100 mb-0">
            <thead class="fs-12 lh-22 fw-700 color-neutral-700 bg-neutral-200 text-center">
              <tr v-for="header in androidMonitoring.header" :key="header">
                <th scope="col" v-for="(value, key) in header" :key="value" class="text-center">
                  <div :style="{ width: (key === 'th_namapemeriksaan' ? '130px' : 'max-content') }" class="mx-auto">{{ value
                  }}</div>
                </th>
              </tr>
            </thead>
            <tbody class="fs-12 lh-22 color-neutral-700">
              <tr v-for="detail in androidMonitoring.detail" :key="detail">
                <td v-for="(value, key) in detail" :key="key">
                  <div
                    :class="{ 'text-center': key !== 'th_namapemeriksaan', 'fw-bolder': detail.hasil_1.trim() === '' }"
                    style="word-break: break-word;"
                    :style="{ width: (key === 'th_namapemeriksaan' ? '130px' : '200px') }"
                  >
                    {{ value }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Loading from '@/components/utility/Loading'
import Empty from '@/components/utility/Empty'
import { onMounted } from 'vue'
import { getAndroidMonitoring, loading, androidMonitoring } from '@/core/mcu'
import { showNotif } from '@/core/utility'
import { useRoute } from 'vue-router'

export default {
  components: { Loading, Empty },
  setup () {
    const route = useRoute()
    const prepareGetMonitoring = async () => {
      try {
        const result = await getAndroidMonitoring({
          kode_rekanan: route.query.kode_rekanan,
          pas_id: route.query.pas_id,
          token_api: route.query.token_api
        })

        if (!result.status) {
          showNotif({
            status: 'error',
            message: status.message
          })
        }
      } catch (error) {
        console.error('error dari AndroidMonitoring prepareGetMonitoring', error)
      }
    }

    const appHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }

    onMounted(async () => {
      window.addEventListener('resize', appHeight)
      appHeight()
      await prepareGetMonitoring()
    })

    return {
      loading,
      androidMonitoring
    }
  }
}
</script>

<style lang="scss" scoped>
:root {
   --app-height: 100%;
}
#patient-monitoring {
  margin-top: 60px;
}

.card-patient {
  .doctor-info {
    border-radius: 11px;
  }

  .icon-wrapper {
    img {
      width: 14px;
      height: 14px;
    }
  }
}

.table-responsive {
  overflow-x: auto;
  height: var(--app-height);

  .table-monitoring {

    thead {
      tr {
        th {
          position: sticky;
          position: -webkit-sticky;
          top: 0;
          background: white;
          z-index: 10;
        }

        th:first-child {
          z-index: 11;
        }
      }
    }

    tbody {
      vertical-align: middle;
    }

    th:first-child,
    td:first-child {
      position: sticky;
      left: 0px;
      padding-left: 20px;
    }

    th:first-child {
      background-color: #edf2f7;
    }

    td:first-child {
      background-color: #f7fafc;
    }
  }

  .table-monitoring> :not(caption)>*>* {
    border-bottom-width: 0px !important;
  }
}
</style>
